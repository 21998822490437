<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
      <SettingsMenu></SettingsMenu>
    </div>
    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <router-view />
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import SettingsMenu from "@/view/pages/core/settings/SettingsMenu";

export default {
  components: {
    SettingsMenu
  },
  mounted() {
    
  },
  methods: {
  
  }
};
</script>
