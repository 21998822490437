<template>
    <div>
        <div class="card card-custom card-stretch">
            <div class="card-header">
                <div class="card-title">
                    <h3 class="card-label">
                        <i class="fas fa-cogs text-primary mr-3"></i>
                        {{ $t("settings.title.system_settings") }}
                    </h3>
                </div>
            </div>
            
            
            <div class="card-body">
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div class="navi-item mb-2">
                        <router-link :to="{ name: 'settings_basic'}" class="navi-link py-4">
                            <span class="navi-icon mr-2">
                            <i class="fas fa-info mr-3"></i>
                            </span>
                            <span class="navi-text font-size-lg">{{ $t("settings.heading.basic") }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div class="navi-item mb-2">
                        <router-link :to="{ name: 'settings_company'}" class="navi-link py-4">
                            <span class="navi-icon mr-2">
                            <i class="far fa-building mr-3"></i>
                            </span>
                            <span class="navi-text font-size-lg">{{ $t("settings.heading.company") }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div class="navi-item mb-2">
                        <router-link :to="{ name: 'settings_login'}" class="navi-link py-4">
                            <span class="navi-icon mr-2">
                            <i class="fas fa-lock mr-3"></i>
                            </span>
                            <span class="navi-text font-size-lg">{{ $t("settings.heading.login") }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div class="navi-item mb-2">
                        <router-link :to="{ name: 'settings_emails'}" class="navi-link py-4">
                            <span class="navi-icon mr-2">
                            <i class="fas fa-envelope-open-text mr-3"></i>
                            </span>
                            <span class="navi-text font-size-lg">{{ $t("settings.heading.emails") }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div class="navi-item mb-2">
                        <router-link :to="{ name: 'settings_apperance'}" class="navi-link py-4">
                            <span class="navi-icon mr-2">
                                <i class="fas fa-pencil-ruler mr-3"></i>
                            </span>
                            <span class="navi-text font-size-lg">{{ $t("settings.heading.apperance") }}</span>
                        </router-link>
                    </div>
                </div>
                

            </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "settings_menu",
  components: {

  },
};
</script>